<template>
    <div>
    </div>
</template>
<script>
    export default {
        name:'index',
        data() {
            return {
                
            }
        },
        created() {
        },
        methods: {
            
        }
    }
</script>

